/* eslint-disable */
const asyncLocalStorage = {
  setItem (key, value) {
    return new Promise((resolve, reject) => {
      resolve(localStorage.setItem(key, value))
    })
  },
  getItem (key) {
    return new Promise((resolve, reject) => {
      resolve(localStorage.getItem('accessToken'))
    })
  }
}

export {
  asyncLocalStorage
}
/* eslint-enable */
