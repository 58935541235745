class utilityHelper {
  static async buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && data.length !== 0) {
      await this.asyncForEach(Object.keys(data), async key => {
        await this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
      })
    } else {
      const value = (data === null) ? '' : data
      formData.append(parentKey, value)
    }

    Promise.resolve()
  }

  static async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index += 1) {
      // eslint-disable-next-line
      await callback(array[index], index, array)
    }
  }
}

export default utilityHelper
