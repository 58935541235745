import axios from '@/libs/axios'
import utilityHelper from '@/helpers/utils'
import http from '@/requests/http'

class Admin {
  static list(params) {
    return axios.post('admin', params)
  }

  static async store(data) {
    const formData = new FormData()

    await utilityHelper.buildFormData(formData, data)

    return http.post('admin/store', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  static view(id) {
    return axios.post(`admin/view/${id}`)
  }

  static async update(data) {
    const formData = new FormData()

    await utilityHelper.buildFormData(formData, data)

    return http.post('admin/update', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  static destroy(id) {
    return axios.post('admin/delete', { id })
  }

  static fetchTalkOperationTimes(params) {
    return axios.post('admin/fetch-talk-operation-time', params)
  }

  static updateSetupLevel(params) {
    return axios.post('admin/setup/level', params)
  }

  static updateSetupTime(params) {
    return axios.post('admin/setup/time', params)
  }

  static updateSetupCompany(params) {
    return axios.post('admin/setup/company', params)
  }

  static forgotPassword(params) {
    return axios.post('auth/admin/forgot-password', params)
  }

  static validateToken(params) {
    return axios.post('auth/admin/forgot-password/validate-token', params)
  }

  static changePassword(params) {
    const config = {
      headers: { Authorization: `Bearer ${params.accessToken}` },
    }
    const bodyParameters = {
      password: params.password,
      email: params.email,
      type: params.type,
    }
    return axios.post('auth/admin/change-password', bodyParameters, config)
  }
}

export default Admin
