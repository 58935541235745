import axios from 'axios'
import store from '@/store'
import router from '@/router'

import { asyncLocalStorage } from '@/helpers/localStorage'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
})

function getToken() {
  return asyncLocalStorage.getItem('accessToken')
}

// Set Headers On Page Load.
httpClient.defaults.headers.common.Accept = 'application/json'
httpClient.defaults.headers.common.Authorization = `Bearer ${typeof store !== 'undefined' ? store.getters.accessToken : ''}` // TODO: Check why store is undefined

httpClient.interceptors.request.use(async config => {
  // Do something before request is sent
  const token = await getToken() // store.dispatch('loadToken')

  // Set Token Headers every API call to refresh the token
  // eslint-disable-next-line
  config.headers.common.Authorization = `Bearer ${token}`

  return Promise.resolve(config)
  // eslint-disable-next-line
}, error => {
  return Promise.reject(error)
})

// eslint-disable-next-line
httpClient.interceptors.response.use(response => {
  // Do something with response data
  return response
// eslint-disable-next-line
}, error => {
  // Do something with response error
  if (error.response.status === 401) {
    store.dispatch('removeToken')
    router.push({ name: 'login' })
    // window.location.replace('/login') // TODO: Check if this is really needed. else, use router.push
  }
  return Promise.reject(error)
})

export default httpClient
